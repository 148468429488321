// for Helmet
export const DEFAULT_HELMET_TITLE = 'Пятница Пицца - Доставка пиццы в Тамбове, Рязани, Пензе и Саранске домой и в офис. Заказать пиццу онлайн из пиццерии Пятница Пицца'
export const DEFAULT_HELMET_TITLE_SHORT = 'Пятница Пицца - Доставка пиццы в Тамбове, Рязани, Пензе и Саранске домой и в офис'
export const HELMET_TITLE = 'Пятница Пицца - Доставка пиццы в {{city}} домой и в офис. Заказать пиццу онлайн на сайте fridaypizza.ru или по телефону {{phone}}'
export const HELMET_TITLE_SHORT = 'Пятница Пицца - Доставка пиццы в {{city}} домой и в офис'
export const DEFAULT_META_DESCRIPTION = 'Заказать доставку пиццы в Тамбове, Рязани, Пензе и Саранске от Пятница Пицца - это купить горячую пиццу с быстрой и бесплатной доставкой в офис или домой. Большая и вкусная пицца всегда рядом в удобное для Вас время!'
export const DEFAULT_META_KEYWORDS = 'пятница пицца, тамбов, рязань, пенза, саранск, доставка пиццы, заказать пиццу, еда доставка, бесплатная доставка'
export const META_DESCRIPTION = 'Заказать доставку пиццы в {{city}} от Пятница Пицца - это купить горячую пиццу с быстрой и бесплатной доставкой в офис или домой. Большая и вкусная пицца всегда рядом в удобное для Вас время!'
export const META_KEYWORDS = 'пятница пицца, {{city}}, доставка пиццы, заказать пиццу, еда доставка, бесплатная доставка'

export const CDN_URL = 'https://abb11bdb-5b7b-4561-8311-fadde7607aa1.selcdn.net'
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ3lyZ2VuMjAyMSIsImEiOiJja29tMTdzemcwOHB5Mm9sNnI3M3piOThmIn0.Tmfi0F1MKUG5d8gNpfmJIA'

export const declensionCities = {
  tambov: ['Тамбов','Тамбове'],
  ryazan: ['Рязань','Разани'],
  penza: ['Пенза','Пензе'],
  saransk: ['Саранск','Саранске'],
  'nizhny-novgorod': ['Нижний Новгород', 'Нижнем Новгороде'],
}
